import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify)

export default new Vuetify({
	theme: {
		dark: false,
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: '#243e89',
				secondary: '#ef9221',
				accent: '#82B1FF',
				error: '#FF5252',
				info: '#243e89',
				success: '#4CAF50',
				warning: '#f39200',
				background: '#efefef'
			},
			dark: {
				primary: '#243e89',
				secondary: '#ef9221',
				accent: '#82B1FF',
				error: '#FF5252',
				info: '#243e89',
				success: '#4CAF50',
				warning: '#f39200'
			},
		},
	},
	icons: {
		iconfont: 'mdi',
	},
});
