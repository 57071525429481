<template>
	<div>
		<!-- Output child page -->
		<router-view />

		<!-- Output navigation if we're not on child page -->
		<template v-if="$route.name == 'pageContainer'">
			<FilterableList
				v-if="items.length"
				:items="items"
				@itemClick="itemClick"
			/>
			<v-container v-else>
				<v-alert type="info">
					Ei sivuja
				</v-alert>
			</v-container>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'PageContainer',
	computed: {
		...mapState({
			items: state => state.pages,
		}),
	},
	methods: {
		itemClick (item) {
			this.$router.push({ name: 'page', params: { pagename: item.name } })
		}
	},
	mounted () {
		this.$api.Pages.doRequest()
	},
};
</script>
