<template>
	<div>
		<!-- Membership card -->
		<v-container>
			<v-card>
				<v-img
					v-if="config.hero_image"
					class="black--text align-end"
					height="200px"
					:src="config.hero_image.url"
					gradient="rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, .05) 100%"
					dark
				>
					<!-- aspect-ratio="1.628" -->
					<h2 class="card-title mb-auto">
						<span class="card-title__img">
							<v-img
								max-width="114"
								contain
								alt="KTK Tekniikan Asiantuntijat ry"
								src="@/assets/logo.png"
							/>
						</span>
						<!-- <span class="card-title__text">
							Jäsenkortti
						</span> -->
					</h2>
					<v-card-title class="headline">
						{{user.firstname}} {{user.lastname}}
					</v-card-title>
					<v-card-subtitle>
						{{user.entity_number}}
					</v-card-subtitle>
				</v-img>
				<template v-else>
					<v-card-title class="headline">
						{{user.firstname}} {{user.lastname}}
					</v-card-title>
					<v-card-subtitle v-if="user.membership">
						{{user.membership}}
					</v-card-subtitle>
				</template>
				<v-card-text v-if="user._details && user._details.length">
					<FilterableList
						:items="user._details"
						subtitleSrc="label"
						titleSrc="value"
						:enableClick="(false)"
						:enableSearch="(false)"
					/>
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-btn
						:to="{ name: 'myAccount' }"
						color="primary"
						text
					>
						<v-icon left>
							mdi-pencil
						</v-icon>
						Muokkaa tietojasi
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-container>

		<!-- Link cards -->
		<v-container v-if="config.links && config.links.length" class="py-0">
			<v-row>
				<v-col
					v-for="item in config.links"
					:key="item.id"
					cols="12"
					sm="6"
					class="pb-2"
				>

					<v-card
						:href="item.link"
						:to="item.to"
						:target="item.link ? '_blank' : null"
						rel="noopener"
						height="100%"
						text="white"
						color="primary"
						tile
					>
						<v-row>
							<v-col cols="12" class="pb-0">
								<v-card-title
									v-if="item.title"
									class="pt-0 pb-3 white--text text-uppercase font-weight-regular"
								>
									{{item.title}}
								</v-card-title>
							</v-col>
						</v-row>
					</v-card>
				</v-col>
			</v-row>
		</v-container>

		<!-- Benefit cards -->
		<v-container v-if="benefits.length" class="py-0">
			<v-row>
				<v-col
					v-for="item in benefits"
					:key="item.id"
					cols="12"
					sm="6"
				>
					<v-card
						:to="{ name: 'benefit', params: { pagename: item.name } }"
						height="100%"
					>
						<v-row>
							<v-col
								:cols="(item.logo) ? 8 : 12"
								class="pb-0"
							>
								<v-card-title
									v-if="item.title"
									class="pt-0"
								>
									{{item.title}}
								</v-card-title>
							</v-col>
							<v-col
								v-if="item.logo"
								cols="4"
							>
								<div class="pt-1 pr-3">
									<v-img
										:src="item.logo.url"
										contain
									/>
								</div>
							</v-col>
						</v-row>

						<v-card-text class="pt-0">
							{{item.summary}}
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'MembershipCard',
	computed: {
		...mapState([
			'user',
			'config',
			'benefits',
		]),
	},
	mounted () {
		this.$api.Me.doRequest()
	},
}
</script>

<style lang="scss" scoped>
	.card-title {
		align-items: flex-end;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		left: 0;
		padding: .5rem;
		position: absolute;
		top: 0;
		width: 100%;

		&__img,
		&__text {
			display: inline-block;
			padding: .5rem;
		}

		&__text {
			opacity: .25;
			text-align: right;
			color: #243e89;
		}
	}
</style>
