<template>
	<div>
		<v-sheet
			v-if="enableSearch"
			class="px-3 pt-6 pb-3"
		>
			<v-text-field
				v-model="searchQuery"
				:label="searchLabel"
				:hide-details="(true)"
				prepend-inner-icon="mdi-magnify"
				rounded
				clearable
				filled
			/>
		</v-sheet>

		<v-list
			v-if="filteredItems.length"
			flat
		>
			<template
				v-for="(item, index) in filteredItems"
			>
				<v-list-item
					:key="item.id"
					:href="(item[redirectUrlSrc]) ? item[redirectUrlSrc] : null"
					:target="(item[redirectUrlSrc]) ? '_blank' : null"
					v-on="enableClick ? { click: () => itemClick(item) } : {}"
				>
					<!-- Avatar -->
					<v-list-item-avatar
						v-if="avatarSrc && item[avatarSrc]"
						:style="{
							borderRadius: avatarRounded ? '50%' : '0',
						}"
					>
            <v-img
							:src="item[avatarSrc]"
							:contain="avatarContain"
						/>
          </v-list-item-avatar>

					<!-- Icon -->
					<v-list-item-icon v-else-if="iconDefault || item[iconSrc]">
						<v-icon
							:color="(item[iconColorSrc]) ? item[iconColorSrc] : 'primary'"
						>
							<template v-if="item[iconSrc]">
								{{item[iconSrc]}}
							</template>
							<template v-else>
								{{iconDefault}}
							</template>
						</v-icon>
					</v-list-item-icon>

					<!-- Content -->
					<v-list-item-content>
						<v-list-item-subtitle v-html="item[subtitleSrc]" />
						<v-list-item-title v-html="item[titleSrc]" />
						<v-list-item-subtitle v-html="item[summarySrc]" />
					</v-list-item-content>

					<!-- Meta -->
					<v-list-item-action v-if="item[metaSrc]">
						<v-list-item-action-text v-html="item[metaSrc]" />
					</v-list-item-action>
				</v-list-item>
				<v-divider
					v-if="index != filteredItems.length - 1" :key="('divider-' + index)"
					:inset="(item.icon && item.icon.length > 0)"
				/>
			</template>
		</v-list>
		<p
			v-else
			class="pa-6"
		>
			{{noResultsText}}
		</p>
	</div>

</template>

<script>

export default {
	name: 'FilterableList',
	data: () => ({
		searchQuery: '',
	}),
	props: {
		// An array of items to render
		items: {
			type: Array,
			default: () => {
				return []
			}
		},

		// Item property to use for item title
		titleSrc: {
			type: String,
			default: () => {
				return 'title'
			}
		},

		// Item property to use for item subtitle
		subtitleSrc: {
			type: String,
			default: () => {
				return 'subtitle'
			}
		},

		// Item property to use for item summary
		summarySrc: {
			type: String,
			default: () => {
				return 'summary'
			}
		},

		// Item property to use for item meta
		// Use this for dates etc.
		metaSrc: {
			type: String,
			default: () => {
				return 'date'
			}
		},

		// Item property to use for icon
		iconSrc: {
			type: String,
			default: () => {
				return 'icon'
			}
		},

		// Item property to use for icon color
		iconColorSrc: {
			type: String,
			default: () => {
				return 'icon_color'
			}
		},

		// Default icon for all items
		iconDefault: {
			type: String,
			default: () => {
				return ''
			}
		},

		// Item property to use for avatar image
		avatarSrc: {
			type: String,
			default: () => {
				return 'avatar'
			}
		},

		// Render avatar as rounded?
		avatarRounded: {
			type: Boolean,
			default: () => {
				return true
			}
		},

		// Use 'contain' mode for avatar image?
		// By default avatar uses 'cover' mode.
		avatarContain: {
			type: Boolean,
			default: () => {
				return false
			}
		},

		// Item property to use for item redirect url
		// If redirect url is defined, user will be redirected
		// when clicking list item.
		redirectUrlSrc: {
			type: String,
			default: () => {
				return 'redirect_url'
			}
		},

		// Allow user to click list items?
		enableClick: {
			type: Boolean,
			default: () => {
				return true
			}
		},

		// Enable search?
		enableSearch: {
			type: Boolean,
			default: () => {
				return true
			}
		},

		// Label for search input
		searchLabel: {
			type: String,
			default: () => {
				return 'Haku'
			}
		},

		// Text to display when there are no results to show
		noResultsText: {
			type: String,
			default: () => {
				return 'Ei hakutuloksia.'
			}
		},
	},
	computed: {
		// Filter items by search query
		filteredItems () {
			if (this.searchQuery) {
				let regex = new RegExp(this.searchQuery, 'i')

				return this.items.filter(item => {
					if (
						regex.test(item[this.titleSrc]) ||
						regex.test(item[this.subtitleSrc]) ||
						regex.test(item[this.summarySrc])
					) return true
				})
			} else {
				return this.items
			}
		},
	},
	methods: {
		// Item click handler
		itemClick (item) {
			if (item[this.redirectUrlSrc]) return

			this.$emit('itemClick', item)
		}
	},
}
</script>
<style lang="scss">

.v-list-item__title {
	overflow: visible;
	white-space: normal;
}

</style>