<template>
	<div>
		<v-toolbar
			color="primary"
			dark
			flat
		>
			<v-tabs
				v-model="currentTab"
				centered
				center-active
				show-arrows
			>
				<v-tabs-slider />
				<v-tab 
				v-for="tab in tabs" 
				:key="tab.name" 
				:to="tab.route" 
				exact-path 
				v-text="tab.title" 
				/>
		</v-tabs>
		</v-toolbar>

		<v-container
				v-if="ready === false"
				class="container--narrow"
			>
				<Spinner />
		</v-container>

		<template v-if="($route.name === 'myAccount')">
			<v-container class="container--narrow">
				<v-card v-if="config.forms && config.forms.myAccount">
					<v-card-text>
						<SchemaToForm
							v-model="formData"
							:schema="config.forms.myAccount"
							:disabled="formLoading"
							:debug="(false)"
							@valid="formValid = true"
							@invalid="formValid = false"
							@change="$store.commit('setConfirmNavigation', true)"
						/>
					</v-card-text>
					<v-divider />
					<v-card-actions>
						<v-spacer />
						<v-btn
							color="success"
							:loading="formLoading"
							:disabled="formLoading || !formValid"
							@click="save"
							text
						>
							Tallenna
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-container>
		</template>
		<template v-else>
			<router-view :key="$route.fullPath" />
		</template>
	</div>
</template>


<script>

import { mapState } from 'vuex'

export default {
	name: 'MyAccount',
	data: () => ({
		ready: false,
		formData: {},
		formLoading: false,
		formValid: false,
		currentTab: 0,
		tabs: [
			{
				name: 'details',
				title: 'Perustiedot',
				route: '/myaccount',
			},
			{
				name: 'password',
				title: 'Salasanan vaihto',
				route: '/myaccount/password',
			},
		],
	}),
	computed: {
		...mapState([
			'user',
			'config',
		]),
	},
	methods: {
		save () {
			this.formLoading = true

			this.$api.Me.doRequest({
				method: 'POST',
				body: this.formData,
			}).on('done', (res) => {
				// Since the register may reformat values (i.e. telephone number),
				// replace form data with server response.
				if (res.body.item) {
					this.formData = res.body.item
				}

				// Clear navigation confirm
				if (!res.body.error){
					this.$nextTick(() => {
						this.$store.dispatch('clearConfirmNavigation')
					})
				}
			}).on('finish', () => {
				this.formLoading = false
			})
		},
	},
	mounted () {
		this.formData = JSON.parse(JSON.stringify(this.user))
		this.ready = true
	},
};
</script>
