<template>
	<v-app :style="{background: this.$vuetify.theme.themes['light'].background}">
		<!-- Error screen -->
		<v-layout
			v-if="error && Object.keys(error).length"
			align-center
			justify-center
		>
			<div class="pa-12 text-center">
				<h1
					v-if="error.title"
				>
					{{error.title}}
				</h1>
				<p
					v-if="error.summary"
					class="mt-3"
				>
					{{error.summary}}
				</p>
				<v-btn
					v-if="error.buttonTitle && (error.buttonHref || error.buttonAction)"
					color="primary"
					class="ma-3"
					@click="errorButtonClick"
				>
					{{error.buttonTitle}}
				</v-btn>
				<v-btn
					color="error"
					class="ma-3"
					@click="logout"
				>
					Kirjaudu ulos
				</v-btn>
			</div>
		</v-layout>

		<!-- Loading screen -->
		<v-layout v-else-if="loading" align-center justify-center>
			<Spinner
				class="pa-12"
				:text="loadingText"
			/>
		</v-layout>

		<!-- Main view -->
		<template v-else>
			<!-- Top bar -->
			<TopBar
				v-if="ready"
				@menu-button-click="drawerOpen = !drawerOpen"
			/>

			<!-- Page content -->
			<v-main>
				<router-view />
			</v-main>

			<!-- Off-canvas drawer -->
			<v-navigation-drawer
				v-if="ready"
				v-model="drawerOpen"
				app
				temporary
				right
				touchless
			>
				<v-list
					dense
					flat
				>
					<v-list-item-group
						v-if="config.social_media_links.length"
						color="primary"
					>
						<v-subheader class="text-uppercase">
							Some
						</v-subheader>
						<v-list-item
							v-for="(item, index) in config.social_media_links"
							:key="index"
							:href="item.url"
							target="_blank"
						>
							<v-list-item-icon v-if="item.icon">
								<v-icon>{{item.icon}}</v-icon>
							</v-list-item-icon>
							<v-list-item-content v-if="item.label">
								{{item.label}}
							</v-list-item-content>
						</v-list-item>
					</v-list-item-group>
					<v-list-item-group
						color="primary"
					>
						<v-subheader class="text-uppercase">
							Sovellus
						</v-subheader>
						<v-list-item
							v-if="$router.options.routes.find(route => route.name == 'myAccount')"
							:to="({ name: 'myAccount' })"
						>
							<v-list-item-icon>
								<v-icon>mdi-account</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								Omat tiedot
							</v-list-item-content>
						</v-list-item>

						<v-list-item
							v-if="$router.options.routes.find(route => route.name == 'settings')"
							:to="({ name: 'settings' })"
						>
							<v-list-item-icon>
								<v-icon>mdi-settings</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								Asetukset
							</v-list-item-content>
						</v-list-item>
						<v-list-item
							@click="logoutDialog = true; drawerOpen = false"
						>
							<v-list-item-icon>
								<v-icon>mdi-logout</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								Kirjaudu ulos
							</v-list-item-content>
						</v-list-item>
					</v-list-item-group>
				</v-list>
			</v-navigation-drawer>

			<!-- Bottom navigation bar -->
			<BottomBar
				v-if="ready"
				:items="bottomBarItems"
			/>
		</template>

		<!-- Navigation confirm dialog -->
		<v-dialog
			v-model="confirmNavigationDialog"
			max-width="300"
		>
			<v-card>
				<v-card-title class="headline justify-center">
					Poistu sivulta?
				</v-card-title>
				<v-card-text class="text-center">
					Tallentamattomat muutokset menetetään.
				</v-card-text>
				<v-card-actions class="justify-center">

					<v-btn
						color="success"
						text
						@click="confirmNavigationDialog = false"
					>
						Peruuta
					</v-btn>
					<v-btn
						color="error"
						text
						@click="confirmNavigation"
					>
						Kyllä
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Logout dialog -->
		<v-dialog
			v-model="logoutDialog"
			max-width="300"
		>
			<v-card>
				<v-card-title class="headline justify-center">
					Kirjaudu ulos?
				</v-card-title>
				<v-card-actions class="justify-center">

					<v-btn
						color="success"
						text
						@click="logoutDialog = false"
					>
						Peruuta
					</v-btn>
					<v-btn
						color="error"
						text
						@click="logout"
					>
						Kyllä
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<Notifications
			:updateAvailable="updateAvailable"
		/>
	</v-app>
</template>

<script>

import { mapState } from 'vuex'
import TopBar from '@/components/TopBar'
import BottomBar from '@/components/BottomBar'
import Notifications from '@/components/Notifications'

export default {
	name: 'App',
	components: {
		TopBar,
		BottomBar,
		Notifications,
	},
	data: () => ({
		ready: false, // Is app ready?
		loading: true, // Is app loading?
		loadingText: '', // Loading description text
		updateAvailable: false, // Is update available?
		registration: null, // Registration for service worker
		drawerOpen: false, // Is off-canvas drawer open?
		logoutDialog: false, // Is logout dialog open?
		bottomBarItems: [], // Array to hold bottom bar navigation items
	}),
	computed: {
		...mapState([
			'error',
			'config',
			'settings',
			'user',
		]),
		confirmNavigationDialog: {
			get () {
				return this.$store.state.confirmNavigationDialog
			},
			set (val) {
				this.$store.commit('setConfirmNavigationDialog', val)
			},
		},
	},
	methods: {
		// Load all app data
		async loadData () {
			this.ready = false
			this.loading = true
			this.$store.commit('clearError')

			new Promise(async (resolve, reject) => {
				let request

				this.loadingText = 'Ladataan asetuksia'
				request = await this.$api.Config.pushRequest()
				if (!request.success) return reject({
					result: request.result,
					summary: 'Asetusten lataaminen ei onnistunut!',
				})

				this.loadingText = 'Ladataan käyttäjätietoja'
				request = await this.$api.Me.pushRequest()
				if (!request.success) return reject({
					result: request.result,
					summary: 'Käyttäjätietojen lataaminen ei onnistunut!',
				})

				this.loadingText = 'Ladataan uutisia'
				request = await this.$api.News.pushRequest()
				if (!request.success) return reject({
					result: request.result,
					summary: 'Uutisten lataaminen ei onnistunut!',
				})

				// this.loadingText = 'Ladataan tapahtumia'
				// request = await this.$api.Events.pushRequest()
				// if (!request.success) return reject({
				// 	result: request.result,
				// 	summary: 'Tapahtumien lataaminen ei onnistunut!',
				// })

				// this.loadingText = 'Ladataan jäsenetuja'
				// request = await this.$api.Benefits.pushRequest()
				// if (!request.success) return reject({
				// 	result: request.result,
				// 	summary: 'Jäsenetujen lataaminen ei onnistunut!',
				// })

				this.loadingText = 'Ladataan sivuja'
				request = await this.$api.Pages.pushRequest()
				if (!request.success) return reject({
					result: request.result,
					summary: 'Sivujen lataaminen ei onnistunut!',
				})

				return resolve()
			}).then(() => {
				this.ready = true
			}).catch(error => {
				if (error.result && error.result.status != 500) {
					this.$store.commit('setError', {
						summary: error.summary,
						buttonTitle: 'Yritä uudelleen',
						buttonAction: this.loadData,
					})
				}
			}).finally(() => {
				this.loading = false
			})
		},

		// Refresh page and force cache bust
		refresh () {
			window.location.reload(true)
		},

		// Log user out
		async logout () {
			this.logoutDialog = false
			this.loading = true
			this.loadingText = 'Kirjaudutaan ulos'

			// Do logout request
			await this.$api.Logout.pushRequest().on('error', () => {
				this.loading = false
				this.error = 'Uloskirjautuminen ei onnistunut!'
			})
		},

		// Error button click handler
		errorButtonClick () {
			if (this.error && Object.keys(this.error).length) {
				if (typeof this.error.buttonAction == 'function') {
					this.error.buttonAction()
				} else if (this.error.buttonHref) {
					window.location.href = this.error.buttonHref
				}
			}
		},

		// When user decides to exit current route even when there are unsaved changes
		confirmNavigation () {
			let route = Object.assign({}, this.$store.state.confirmNavigationRoute)

			this.$store.dispatch('clearConfirmNavigation')
			this.$router.push(route)
		},

		handleCaptchaReturn () {
			const captchaReturnUrl = sessionStorage.getItem('captcha_return_url')
			if (!captchaReturnUrl) return

			sessionStorage.removeItem('captcha_return_url')

			if (captchaReturnUrl == window.location) return

			window.location.href = captchaReturnUrl
		},

	},
	watch: {
		// Monitor user settings changes
		settings: {
			deep: true,
			immediate: true,
			handler (val) {
				// Switch light/dark mode
				this.$vuetify.theme.dark = val.darkMode
			}
		}
	},
	async mounted () {
		this.handleCaptchaReturn()
		// Load user settings from localStorage
		await this.$store.dispatch('getSettings')

		// Get bottom nav items
		this.bottomBarItems = this.$router.options.routes.filter(item => {
			return (!item.meta || !item.meta.hidden)
		})

		// Load data from API
		this.loadData()
	},
	created () {
		// When service worker has an update available, notify user
		document.addEventListener('swUpdated', e => {
			this.updateAvailable = true
			this.registration = e.detail
		})
	},
};
</script>

<style lang="scss">

	// CKEditor content
	.copy {
		width: 100%;
		max-width: 100%;
		overflow-x: hidden;

		h2,
		h3,
		h4 {
			margin: 0 0 1rem;

			&:not(:first-child) {
				margin-top: 2rem;
			}
		}

		p {
			margin: 0 0 1rem;
		}

		table {
			width: auto !important;
			max-width: 100%;
		}

		th,
		td {
			padding: .5em;
			vertical-align: top;
		}

		img {
			width: 100%;
			max-width: 100%;
			height: auto;
			margin: 0 0 1rem 0;

			&.align_left,
			&.align_right {
				width: 40%;
			}

			&.align_left {
				float: left;
				margin-right: 1rem;
			}

			&.align_right {
				float: right;
				margin-left: 1rem;
			}
		}

		// Clearfix
		&:after {
			content: ' ';
			display: block;
			float: none;
		}

		// Horizontally scrollable tables
		.tablewrapper {
			width: 100%;
			max-width: 100%;
			overflow-y: visible;
			overflow-x: scroll;
		}

		table {
			max-width: 100%;
			border-collapse: collapse;
			border: none;
		}
	}

	.no-line-break {
		white-space: nowrap;
	}

	// Temporary fix for bottom navigation bug
	.v-item-group.v-bottom-navigation .v-btn.v-size--default {
		height: inherit;
	}

	// Fix word breaking in cards
	.v-card__text,
	.v-card__title {
		word-break: normal !important;
	}

</style>
