import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Error404 from '@/views/Error404.vue'
import MembershipCard from '@/views/MembershipCard.vue'
import MyAccount from '@/views/MyAccount.vue'
import PasswordResetForm from '@/views/PasswordResetForm.vue'
// import Settings from '@/views/Settings.vue'
// import BenefitContainer from '@/views/BenefitContainer.vue'
// import Benefit from '@/views/Benefit.vue'
// import EventContainer from '@/views/EventContainer.vue'
// import Event from '@/views/Event.vue'
import NewsContainer from '@/views/NewsContainer.vue'
import News from '@/views/News.vue'
import PageContainer from '@/views/PageContainer.vue'
import Page from '@/views/Page.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: "/",
		name: "membershipCard",
		component: MembershipCard,
		meta: {
			title: "Jäsenkortti",
			icon: "mdi-account-card-details"
		}
	},
	{
		path: "/myaccount",
		name: "myAccount",
		component: MyAccount,
		meta: {
			title: "Omat tiedot",
			headline: "Omat tiedot",
			icon: "mdi-account",
			hidden: true
		},
		children: [
			{
				path: 'password',
				name: 'password',
				component: PasswordResetForm,
				meta: {
					title: 'Salasanan vaihto',
					headline: 'Salasanan vaihto',
				}
			},
		],
	},
	// {
	// 	path: "/settings",
	// 	name: "settings",
	// 	component: Settings,
	// 	meta: {
	// 		title: "Asetukset",
	// 		headline: "Asetukset",
	// 		icon: "mdi-settings",
	// 		hidden: true
	// 	}
	// },
	// {
	// 	path: "/benefits",
	// 	name: "benefitContainer",
	// 	component: BenefitContainer,
	// 	meta: {
	// 		title: "Jäsenedut",
	// 		headline: "Jäsenedut",
	// 		icon: "mdi-star"
	// 	},
	// 	children: [
	// 		{
	// 			path: ":pagename",
	// 			name: "benefit",
	// 			component: Benefit,
	// 			props: true,
	// 			meta: {
	// 				title: "Jäsenetu"
	// 			}
	// 		}
	// 	]
	// },
	// {
	// 	path: "/events",
	// 	name: "eventContainer",
	// 	component: EventContainer,
	// 	meta: {
	// 		title: "Tapahtumat",
	// 		headline: "Jäsenedut",
	// 		icon: "mdi-calendar"
	// 	},
	// 	children: [
	// 		{
	// 			path: ":pagename",
	// 			name: "event",
	// 			component: Event,
	// 			props: true,
	// 			meta: {
	// 				title: "Tapahtuma"
	// 			}
	// 		}
	// 	]
	// },
	{
		path: "/news",
		name: "newsContainer",
		component: NewsContainer,
		meta: {
			title: "Uutiset",
			headline: "Uutiset",
			icon: "mdi-bullhorn"
		},
		children: [
			{
				path: ":pagename",
				name: "news",
				component: News,
				props: true,
				meta: {
					title: "Uutinen"
				}
			}
		]
	},
	{
		path: "/pages",
		name: "pageContainer",
		component: PageContainer,
		meta: {
			title: "KTK",
			headline: "KTK",
			icon: "mdi-file-document-box-multiple"
		},
		children: [
			{
				path: ":pagename",
				name: "page",
				component: Page,
				props: true,
				meta: {
					title: "Sivu"
				}
			}
		]
	},
	{
		name: "Error404",
		path: "/error404",
		component: Error404,
		meta: {
			title: "Sivua ei löytynyt",
			hidden: true
		}
	},
	{
		path: "*",
		redirect: {
			name: "Error404"
		},
		meta: {
			hidden: true
		}
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

// Navigation guards
router.beforeEach((to, from, next) => {
	// Store scroll position
	let scrollY = window.scrollY

	from.meta.scrollY = scrollY

	// Store previous route
	if (to.matched.length > 1) {
		if (from.name) {
			to.meta.prevRoute = {
				name: from.name,
			}
		} else {
			to.meta.prevRoute = {
				name: to.matched[to.matched.length - 2].name
			}
		}
	} else {
		to.meta.prevRoute = null
	}

	// If navigation confirmation is requested, show dialog
	if (store.state.confirmNavigation) {
		store.commit('setConfirmNavigationDialog', true)
		store.commit('setConfirmNavigationRoute', to)
	} else {
		next()
	}
})

router.afterEach((to) => {
	// Change meta title
	if (to.meta && to.meta.title) {
		document.title = to.meta.title
	} else {
		document.title = to.name
	}
})

export default router
