<template>
	<div v-if="item">
		<v-container
			v-if="item.hero_image"
			fluid
			pa-0
		>
			<v-img
				:src="item.hero_image.url"
				:alt="item.hero_image.alt"
				height="200"
			/>
		</v-container>
		<v-container class="py-6" background-color="white">
			<h1
				v-if="item.title"
				class="headline mb-5"
			>
				{{item.title}}
			</h1>
			<p
				v-if="item.summary"
				class="subtitle-1 mb-6"
			>
				{{item.summary}}
			</p>
			<div
				v-if="item.content"
				v-html="item.content"
				class="copy"
			/>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Page',
	data: () => ({
		error: '',
		item: null,
	}),
	computed: {
		...mapState([
			'pages',
		]),
	},
	mounted () {
		// Find current page from the store
		this.item = this.pages.find(item => {
			return item.name == this.$route.params.pagename
		})

		if (!this.item) {
			this.$router.replace({ name: 'Error404' })
		}

		this.loading = false
	},
};
</script>
