<template>
	<v-container class="container--narrow">
		<v-card>
			<v-card-text>
				<SchemaToForm
					v-model="formData"
					:schema="formSchema"
					:disabled="formLoading"
					:debug="(false)"
					@valid="formValid = true"
					@invalid="formValid = false"
				/>
			</v-card-text>
			<v-divider />
			<v-card-actions>
				<v-spacer />
				<v-btn
					color="primary"
					:loading="formLoading"
					:disabled="formLoading || !formValid"
					@click="save"
					text
				>
				Tallenna
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'PasswordResetForm',

	data: () => ({
		formSchema: {},
		formData: {},
		formLoading: false,
		formValid: true,
	}),

	computed: {
		...mapState([
			'user',
			'config',
		]),
	},

	mounted () {
		this.formSchema = JSON.parse(JSON.stringify(this.config.forms.passwordReset));
	},

	methods: {
		save() {
			this.formloading = true

			this.$api.Password.doRequest({
				method: 'POST',
				body: this.formData,
			}).on('done', (res) => {
				if(res.body.error) return
				
				const parentRoute = this.$route.matched[this.$route.matched.length -2]
				this.$router.replace(parentRoute)
			}).on('finish', () => {
				this.formLoading = false
			})
		},
	},
}

</script>