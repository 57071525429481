<template>
	<div v-if="item">
		<v-container
			v-if="item.hero_image"
			fluid
			pa-0
		>
			<v-img
				:src="item.hero_image.url"
				:alt="item.hero_image.alt"
				height="200"
			/>
		</v-container>
		<v-container class="py-6">
			<h1
				v-if="item.title"
				class="headline mb-5"
			>
				{{item.title}}
			</h1>
			<p
				v-if="item.date"
				class="subtitle-1"
			>
				{{item.date}}
			</p>
			<div
				v-if="item.summary"
				v-html="item.summary"
				class="copy mb-4"
			/>
			<v-btn
				v-if="item.link"
				:href="item.link"
				target="_blank"
				color="warning"
				tile
			>
				Lue koko uutinen
			</v-btn>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'News',
	data: () => ({
		loading: true,
		error: '',
		item: null,
	}),
	computed: {
		...mapState([
			'news',
		])
	},
	mounted () {
		// Find current page from the store
		this.item = this.news.find(item => {
			return item.name == this.$route.params.pagename
		})

		if (!this.item) {
			this.$router.replace({ name: 'Error404' })
		}

		this.loading = false
	},
};
</script>
